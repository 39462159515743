import { Player } from './player';

export const PLAYERS: Player[] = [
  { id: 1, name: 'LRP' },
  { id: 2, name: 'Studley' },
  { id: 3, name: 'Threes' },
  { id: 4, name: 'Chops' },
  { id: 5, name: 'Yoda' },
  { id: 6, name: 'Baps' },
  { id: 7, name: 'DaDawg' },
  { id: 8, name: 'J5' },
  
];